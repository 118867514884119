// Tables 

.table {
  padding: 0.65rem 0.65rem;
  margin-bottom: 0;

  >:not(:last-child)>:last-child>* {
		border-bottom-color: inherit;
	}

  thead {
    th {
      border-top: 0;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: $text-muted;
      i {
        margin-left: 0.325rem;
      }
    }
  }

  th,
  td {
    white-space: nowrap;
    // text-align: center;
    vertical-align: middle;
  }
  
  td {
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
  }
}


.table > :not(caption) > * > * {
  padding: 0.45rem 0.85rem;
  font-size: 14px;
}