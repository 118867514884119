// Write your custom css/scss here

.strong{
    font-weight: 900 !important;
}

.link{
    font-weight: 700 !important;
    color: #a1864b;
}
.link:hover{
    // text-decoration: underline;
}
.pointer{
    cursor: pointer;
}

.select-column{
    width: 80px;
}

.selectBulk{
    min-width: 150px;
}

label{
    color: #a0a0a0;
}

sup{
    color: red;
}

.table thead th{
    background-color: #f0f0f0;
    color: #606060;
}

.table thead th label{    
    color: #606060;
}

.table{
    font-size: 14px;
}

.card{
    border-radius: 10px;
}

.overflow-x-visible{
    overflow-x: visible;
}

.sign-invoice::before{
    content: '\f573';
    font-family: 'Font Awesome 5 Pro';    
    font-weight: 900;
}
